import dd from "dingtalk-jsapi"

/**
 * 设置钉钉中的标题
 * @param title
 * @param ddCallback
 */
export const useDDCommonEffects = (title: string, ddCallback: (userInDD: boolean) => void) => {
  try {
    if (dd.env.platform !== "notInDingTalk") {
      dd.ready(function () {
        dd.biz.navigation.setTitle({
          title: title
        })
        ddCallback(true)
      })
    } else {
      ddCallback(false)
    }
  } catch (err) {
    ddCallback(false)
  }
}
