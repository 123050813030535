
import { defineComponent, PropType } from "vue"
import { ReportTableItem } from "@/interface/reportModels"

export default defineComponent({
  name: "CardView",
  props: {
    title: {
      type: String,
      default: () => "主要指标"
    },
    items: {
      type: Array as PropType<ReportTableItem[]>,
      required: true
    }
  },
  setup (props, { emit }) {
    const itemClick = (index: number, item: ReportTableItem) => {
      emit("itemClick", { index, item })
    }
    return {
      itemClick
    }
  }
})
