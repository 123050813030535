
import { defineComponent, ref } from "vue"
import { useRouter } from "vue-router"
import { Toast } from "vant"

import { post } from "@/request/request"
import { useStore } from "vuex"

export default defineComponent({
  name: "ModifyPassword",
  setup () {
    const router = useRouter()
    const onClickLeft = () => {
      router.go(-1)
    }

    const {
      originPassword,
      newPassword,
      copyPassword,
      modifyPasswordClick
    } = modifyPasswordEffect()

    return {
      onClickLeft,
      modifyPasswordClick,
      originPassword,
      newPassword,
      copyPassword
    }
  }
})

const modifyPasswordEffect = () => {
  const store = useStore()
  const router = useRouter()
  const originPassword = ref("")
  const newPassword = ref("")
  const copyPassword = ref("")
  const modifyPasswordClick = () => {
    if (!originPassword.value || !newPassword.value || !copyPassword.value) {
      Toast("密码不能为空")
      return
    }
    if (newPassword.value !== copyPassword.value) {
      Toast("两次密码输入不一致")
      return
    }
    post("/api/user/resetPassword", {
      token: store.state.user.token,
      oldpassword: originPassword.value,
      newpassword: newPassword.value
    }).then(response => {
      Toast("密码修改成功，请重新登录")
      store.commit("logout")
      router.replace({ name: "login" })
    })
  }
  return {
    originPassword,
    newPassword,
    copyPassword,
    modifyPasswordClick
  }
}

