
import { computed, defineComponent, onActivated, reactive, ref, toRefs, watch } from "vue"
import { useStore } from "vuex"
import { useRouter } from "vue-router"

import { get } from "@/request/request"
import { ReportTableType } from "@/interface/reportModels"
import { UserState } from "@/interface/userModel"
import { useDDCommonEffects } from "@/commonEffects/ddEffects"

import CardView from "@/components/CardView.vue"
import { isMobilePhone } from "@/utils/device"

export default defineComponent({
  name: "Home",
  components: {
    CardView
  },
  setup () {
    const {
      user,
      homeListData,
      handleClick
    } = homeListEffect()
    const {
      needNav,
      wrapperClass
    } = useDDEffects("BI报表")

    return {
      needNav,
      wrapperClass,
      homeListData,
      handleClick,
      user
    }
  }
})

/**
 * 钉钉相关
 * @param title
 */
const useDDEffects = (title: string) => {
  const needNav = ref(true)
  const wrapperClass = computed(() => {
    return {
      wrapper__dd: needNav.value,
      wrapper__banner: true
    }
  })
  onActivated(() => {
    useDDCommonEffects(title, (useInDD) => {
      needNav.value = !(useInDD && isMobilePhone())
    })
  })
  return {
    needNav,
    wrapperClass
  }
}

/*
*  主页数据抓取
* */
const homeListEffect = () => {
  const router = useRouter()
  const store = useStore<UserState>()
  const { user } = toRefs(store.state)
  const homeListData = reactive({
    typeList: [] as ReportTableType[]
  })

  watch(() => store.state.user, (after, before) => {
    console.log(`${after} --- ${before}`)
    if (after != null) {
      getHomeListData()
    } else {
      homeListData.typeList = []
    }
  })

  const getHomeListData = () => {
    get("/api/index/index", { token: user.value.token }).then((data: any) => {
      homeListData.typeList = data
    })
  }

  getHomeListData()

  const handleReportItemClick = ({
    item
  }: any) => {
    router.push({
      name: "report",
      query: {
        url: item.url,
        name: item.name
      }
    })
  }
  return {
    user,
    homeListData,
    handleClick: handleReportItemClick
  }
}

