
import { computed, defineComponent, onMounted, reactive, ref, toRefs } from "vue"
import { VxeTablePropTypes } from "vxe-table"
import { useRoute, useRouter } from "vue-router"

import TableColumn from "@/components/TableColumn.vue"

import { get } from "@/request/request"
import { useStore } from "vuex"
import { UserState } from "@/interface/userModel"
import { useDDCommonEffects } from "@/commonEffects/ddEffects"
import { isMobilePhone } from "@/utils/device"

export default defineComponent({
  name: "Report",
  components: {
    TableColumn
  },
  setup () {
    const tableHeight = ref(document.documentElement.clientHeight - 180)
    const route = useRoute()
    const params = route.query
    const title = ref(params.name as string || "")

    const onClickLeft = routerEffect()
    const {
      needNav,
      wrapperClass
    } = useDDEffects(params.name as string)

    const {
      reportData,
      config,
      haveCalendar,
      updateTableData
    } = useTableDataEffects()

    const {
      showCalendar,
      dateText,
      calendarClick,
      calendarConfirm
    } = userCalendarEffects(updateTableData)

    onMounted(() => {
      updateTableData()
    })

    return {
      needNav,
      wrapperClass,
      title,
      tableHeight,
      onClickLeft,
      config,
      reportData,
      haveCalendar,
      showCalendar,
      dateText,
      calendarClick,
      calendarConfirm
    }
  }
})

/**
 * 钉钉相关
 * @param title
 */
const useDDEffects = (title: string) => {
  const needNav = ref(true)
  const wrapperClass = computed(() => {
    return { wrapper: needNav.value }
  })

  useDDCommonEffects(title, (useInDD) => {
    needNav.value = !(useInDD && isMobilePhone())
  })

  return {
    needNav,
    wrapperClass
  }
}

/**
 *  路由相关
 */
const routerEffect = () => {
  const router = useRouter()
  return () => router.go(-1)
}

/**
 *  日历相关
 */
const userCalendarEffects = (
  updateTableData: any
) => {
  const showCalendar = ref(false)
  const dateText = ref("请选择筛选日期")

  const calendarConfirm = (dates: any) => {
    const [start, end] = dates
    showCalendar.value = false
    dateText.value = `${formatDate(start)} 至 ${formatDate(end)}`
    updateTableData(start, end)
  }

  const calendarClick = () => {
    showCalendar.value = !showCalendar.value
  }

  return {
    showCalendar,
    dateText,
    calendarClick,
    calendarConfirm
  }
}

const formatDate = (date: Date) => {
  return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
}

/**
 * 请求与更新报表数据
 */
const useTableDataEffects = () => {
  const store = useStore<UserState>()
  const route = useRoute()
  const params = route.query
  const url: any = params.url
  const { user } = toRefs(store.state)
  const haveCalendar = ref(false)
  const globalFilter: any = {}
  const startDate = ref()
  const endDate = ref()

  const filterParams = reactive({
    filters: {}
  })

  const config = reactive({
    cellClassName: String as VxeTablePropTypes.CellClassName,
    filter: {
      models: [],
      filterChanged: () => {
        const params: any = {}
        for (let i = 0; i < config.filter.models.length; i++) {
          const model: any = config.filter.models[i]
          params[model.key] = model.value
          globalFilter[model.key] = model.value
        }
        filterParams.filters = params
        updateTableData()
      }
    }
  })
  const reportData = reactive({
    loading: false,
    data: {
      config: {
        filter: [] as any[],
        merge: [] as any[],
        dateFilter: {
          startDate: String,
          endDate: String,
          dateFilterStatus: Boolean
        }
      },
      table: {
        header: [] as any[],
        body: [] as any
      }
    }
  })
  reportData.loading = true

  const updateTableData = async (start?: Date, end?: Date) => {
    const params: any = filterParams.filters
    params.token = user.value.token
    console.log(startDate.value, endDate.value)
    if ((start && end)) {
      params.startDate = formatDate(start)
      params.endDate = formatDate(end)
      startDate.value = start
      endDate.value = end
    } else if ((startDate.value && endDate.value)) {
      params.startDate = formatDate(startDate.value)
      params.endDate = formatDate(endDate.value)
    }
    reportData.loading = true
    get(url, params).then(
      (data: any) => {
        reportData.data = data
        reportData.loading = false
        if (reportData.data.config.dateFilter) {
          haveCalendar.value = true
        }
        handleReportDataEffect(reportData, config, globalFilter)
      })
  }
  return {
    reportData,
    updateTableData,
    startDate,
    endDate,
    config,
    haveCalendar
  }
}

/**
 * 处理报表数据  生成筛选
 * @param reportData
 * @param config
 * @param globalFilter
 */
const handleReportDataEffect = (
  reportData: any,
  config: any,
  globalFilter: any
) => {
  const cellClassName: VxeTablePropTypes.CellClassName = ({ rowIndex }) => {
    const item = reportData.data.body.tableData[rowIndex]
    if (item.age > 30) {
      console.log(item.age)
      return "col-red"
    }
    return null
  }
  config.cellClassName = cellClassName

  const models: {
    key: string
    value: number
    values: { text: string; value: number }[]
    changed: (value: string) => void
  }[] = []
  for (let i = 0; i < reportData.data.config.filter.length; i++) {
    const item = reportData.data.config.filter[i]
    if (item.values && item.values.length !== 0) {
      const model = reactive({
        key: item.key,
        value: globalFilter[item.key] ?? item.values[0].value,
        values: item.values,
        changed: (value: string) => {
          config.filter.filterChanged()
        }
      })
      models.push(model)
    }
  }
  config.filter.models = models

  return {
    cellClassName,
    models
  }
}
