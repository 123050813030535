import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TableColumn = _resolveComponent("TableColumn", true)!
  const _component_vxe_colgroup = _resolveComponent("vxe-colgroup")!
  const _component_vxe_column = _resolveComponent("vxe-column")!

  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item) => {
    return (_openBlock(), _createElementBlock(_Fragment, {
      key: item.field
    }, [
      (item.items.length > 0)
        ? (_openBlock(), _createBlock(_component_vxe_colgroup, {
            key: 0,
            title: item.title,
            field: item.field
          }, {
            default: _withCtx(() => [
              _createVNode(_component_TableColumn, {
                items: item.items
              }, null, 8, ["items"])
            ]),
            _: 2
          }, 1032, ["title", "field"]))
        : (_openBlock(), _createBlock(_component_vxe_column, {
            key: 1,
            field: item.field,
            width: item.width,
            title: item.title
          }, null, 8, ["field", "width", "title"]))
    ], 64))
  }), 128))
}