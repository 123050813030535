

import { defineComponent, PropType } from "vue"
import { TableColumnItem } from "@/interface/reportModels"

export default defineComponent({
  name: "TableColumn",
  props: {
    items: {
      type: Array as PropType<TableColumnItem[]>,
      default: () => []
    }
  },
  setup (props, ctx) { }
})
